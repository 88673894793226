import revive_payload_client_4sVQNw7RlN from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_vT9JWWT9pN from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/plugins/auth.ts";
import casl_7eGVIGiOQa from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/plugins/casl.js";
import caslStorePlugin_2XZOpv0jMH from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/plugins/caslStorePlugin.ts";
import farbcode_core_rz2AqV8wmd from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/plugins/farbcode-core.js";
import vee_validate_K3WwmJMPDb from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/plugins/vee-validate.js";
import ziggy_861J4JMJOx from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/plugins/ziggy.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  auth_vT9JWWT9pN,
  casl_7eGVIGiOQa,
  caslStorePlugin_2XZOpv0jMH,
  farbcode_core_rz2AqV8wmd,
  vee_validate_K3WwmJMPDb,
  ziggy_861J4JMJOx
]