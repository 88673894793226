import validate from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/node_modules/nuxt/dist/pages/runtime/validate.js";
import current_45module_45global from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/middleware/currentModule.global.js";
import is_45authenticated_45global from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/middleware/isAuthenticated.global.js";
import set_45language_45global from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/middleware/setLanguage.global.js";
export const globalMiddleware = [
  validate,
  current_45module_45global,
  is_45authenticated_45global,
  set_45language_45global
]
export const namedMiddleware = {
  auth: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/middleware/auth.ts"),
  "can-access-route": () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/middleware/canAccessRoute.js"),
  guest: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/middleware/guest.ts"),
  unverified: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/middleware/unverified.ts"),
  verified: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/middleware/verified.ts")
}