import { default as index6Fa2OtohvnMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/auth/index.vue?macro=true";
import { default as indexyWkznj2xznMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as indexYrGGo4CJTEMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/index/index.vue?macro=true";
import { default as orderAt4WrtmXeJMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/index/order.vue?macro=true";
import { default as orderSuccessH2CNd0Uau9Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/index/orderSuccess.vue?macro=true";
import { default as indexElc7wf7on7Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/index.vue?macro=true";
import { default as attributeBaseDatawD850dhstpMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/attributes/[id]/attributeBaseData.vue?macro=true";
import { default as _91id_93uNqVMWbuD6Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/attributes/[id].vue?macro=true";
import { default as componentBaseDatadGioK8OAdEMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/components/[id]/componentBaseData.vue?macro=true";
import { default as _91id_93CExnEZiXESMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/components/[id].vue?macro=true";
import { default as indexKrN1oVZebXMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/components/index.vue?macro=true";
import { default as variantBaseDataHu3d7ZHMn2Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/variants/[id]/variantBaseData.vue?macro=true";
import { default as _91id_93SURMi51bCBMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/variants/[id].vue?macro=true";
import { default as index2DwUn4qPhgMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/variants/index.vue?macro=true";
import { default as configurator2cFpIDeGroMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator.vue?macro=true";
import { default as indexbq6USNLEO8Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/index.vue?macro=true";
import { default as orderBaseDataO9jYIh1nF6Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/orders/[id]/orderBaseData.vue?macro=true";
import { default as _91id_93sGL2GV9A0KMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/orders/[id].vue?macro=true";
import { default as indexvlRQBVKdQgMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/orders/index.vue?macro=true";
import { default as languageew28PfUt3QMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/settings/language.vue?macro=true";
import { default as _91id_93v3Ej1MywC2Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/settings/loginProviders/[id].vue?macro=true";
import { default as indexKHnqSjHnHFMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/settings/loginProviders/index.vue?macro=true";
import { default as rolesynKZP8aKfXMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/settings/roles.vue?macro=true";
import { default as settingsrGf2sDojyDMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/settings.vue?macro=true";
import { default as userActivitiesLJwlhU58MdMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataF1uUUE8C60Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsbqUAM2E0cSMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93xmY2RRoPg6Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/users/[id].vue?macro=true";
import { default as index5S6EfTLjAjMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/users/index.vue?macro=true";
import { default as usersWSZrYreI4GMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/users.vue?macro=true";
import { default as serviceklnPIKRbtpMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service.vue?macro=true";
export default [
  {
    name: index6Fa2OtohvnMeta?.name ?? "auth",
    path: index6Fa2OtohvnMeta?.path ?? "/auth",
    meta: index6Fa2OtohvnMeta || {},
    alias: index6Fa2OtohvnMeta?.alias || [],
    redirect: index6Fa2OtohvnMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexyWkznj2xznMeta?.name ?? "auth-social-organisation-provider",
    path: indexyWkznj2xznMeta?.path ?? "/auth/social/:organisation()/:provider()",
    meta: indexyWkznj2xznMeta || {},
    alias: indexyWkznj2xznMeta?.alias || [],
    redirect: indexyWkznj2xznMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    path: indexElc7wf7on7Meta?.path ?? "/",
    children: [
  {
    name: indexYrGGo4CJTEMeta?.name ?? "index",
    path: indexYrGGo4CJTEMeta?.path ?? "",
    meta: indexYrGGo4CJTEMeta || {},
    alias: indexYrGGo4CJTEMeta?.alias || [],
    redirect: indexYrGGo4CJTEMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: orderAt4WrtmXeJMeta?.name ?? "index-order",
    path: orderAt4WrtmXeJMeta?.path ?? "order",
    meta: orderAt4WrtmXeJMeta || {},
    alias: orderAt4WrtmXeJMeta?.alias || [],
    redirect: orderAt4WrtmXeJMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/index/order.vue").then(m => m.default || m)
  },
  {
    name: orderSuccessH2CNd0Uau9Meta?.name ?? "index-orderSuccess",
    path: orderSuccessH2CNd0Uau9Meta?.path ?? "orderSuccess",
    meta: orderSuccessH2CNd0Uau9Meta || {},
    alias: orderSuccessH2CNd0Uau9Meta?.alias || [],
    redirect: orderSuccessH2CNd0Uau9Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/index/orderSuccess.vue").then(m => m.default || m)
  }
],
    name: indexElc7wf7on7Meta?.name ?? undefined,
    meta: indexElc7wf7on7Meta || {},
    alias: indexElc7wf7on7Meta?.alias || [],
    redirect: indexElc7wf7on7Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/index.vue").then(m => m.default || m)
  },
  {
    path: serviceklnPIKRbtpMeta?.path ?? "/service",
    children: [
  {
    name: configurator2cFpIDeGroMeta?.name ?? "service-configurator",
    path: configurator2cFpIDeGroMeta?.path ?? "configurator",
    children: [
  {
    name: _91id_93uNqVMWbuD6Meta?.name ?? "service-configurator-attributes-id",
    path: _91id_93uNqVMWbuD6Meta?.path ?? "attributes/:id()",
    children: [
  {
    name: attributeBaseDatawD850dhstpMeta?.name ?? "service-configurator-attributes-id-attributeBaseData",
    path: attributeBaseDatawD850dhstpMeta?.path ?? "attributeBaseData",
    meta: attributeBaseDatawD850dhstpMeta || {},
    alias: attributeBaseDatawD850dhstpMeta?.alias || [],
    redirect: attributeBaseDatawD850dhstpMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/attributes/[id]/attributeBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93uNqVMWbuD6Meta || {},
    alias: _91id_93uNqVMWbuD6Meta?.alias || [],
    redirect: _91id_93uNqVMWbuD6Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/attributes/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93CExnEZiXESMeta?.name ?? "service-configurator-components-id",
    path: _91id_93CExnEZiXESMeta?.path ?? "components/:id()",
    children: [
  {
    name: componentBaseDatadGioK8OAdEMeta?.name ?? "service-configurator-components-id-componentBaseData",
    path: componentBaseDatadGioK8OAdEMeta?.path ?? "componentBaseData",
    meta: componentBaseDatadGioK8OAdEMeta || {},
    alias: componentBaseDatadGioK8OAdEMeta?.alias || [],
    redirect: componentBaseDatadGioK8OAdEMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/components/[id]/componentBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93CExnEZiXESMeta || {},
    alias: _91id_93CExnEZiXESMeta?.alias || [],
    redirect: _91id_93CExnEZiXESMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/components/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKrN1oVZebXMeta?.name ?? "service-configurator-components",
    path: indexKrN1oVZebXMeta?.path ?? "components",
    meta: indexKrN1oVZebXMeta || {},
    alias: indexKrN1oVZebXMeta?.alias || [],
    redirect: indexKrN1oVZebXMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/components/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93SURMi51bCBMeta?.name ?? "service-configurator-variants-id",
    path: _91id_93SURMi51bCBMeta?.path ?? "variants/:id()",
    children: [
  {
    name: variantBaseDataHu3d7ZHMn2Meta?.name ?? "service-configurator-variants-id-variantBaseData",
    path: variantBaseDataHu3d7ZHMn2Meta?.path ?? "variantBaseData",
    meta: variantBaseDataHu3d7ZHMn2Meta || {},
    alias: variantBaseDataHu3d7ZHMn2Meta?.alias || [],
    redirect: variantBaseDataHu3d7ZHMn2Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/variants/[id]/variantBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93SURMi51bCBMeta || {},
    alias: _91id_93SURMi51bCBMeta?.alias || [],
    redirect: _91id_93SURMi51bCBMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/variants/[id].vue").then(m => m.default || m)
  },
  {
    name: index2DwUn4qPhgMeta?.name ?? "service-configurator-variants",
    path: index2DwUn4qPhgMeta?.path ?? "variants",
    meta: index2DwUn4qPhgMeta || {},
    alias: index2DwUn4qPhgMeta?.alias || [],
    redirect: index2DwUn4qPhgMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator/variants/index.vue").then(m => m.default || m)
  }
],
    meta: configurator2cFpIDeGroMeta || {},
    alias: configurator2cFpIDeGroMeta?.alias || [],
    redirect: configurator2cFpIDeGroMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/configurator.vue").then(m => m.default || m)
  },
  {
    name: indexbq6USNLEO8Meta?.name ?? "service",
    path: indexbq6USNLEO8Meta?.path ?? "",
    meta: indexbq6USNLEO8Meta || {},
    alias: indexbq6USNLEO8Meta?.alias || [],
    redirect: indexbq6USNLEO8Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sGL2GV9A0KMeta?.name ?? "service-orders-id",
    path: _91id_93sGL2GV9A0KMeta?.path ?? "orders/:id()",
    children: [
  {
    name: orderBaseDataO9jYIh1nF6Meta?.name ?? "service-orders-id-orderBaseData",
    path: orderBaseDataO9jYIh1nF6Meta?.path ?? "orderBaseData",
    meta: orderBaseDataO9jYIh1nF6Meta || {},
    alias: orderBaseDataO9jYIh1nF6Meta?.alias || [],
    redirect: orderBaseDataO9jYIh1nF6Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/orders/[id]/orderBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93sGL2GV9A0KMeta || {},
    alias: _91id_93sGL2GV9A0KMeta?.alias || [],
    redirect: _91id_93sGL2GV9A0KMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexvlRQBVKdQgMeta?.name ?? "service-orders",
    path: indexvlRQBVKdQgMeta?.path ?? "orders",
    meta: indexvlRQBVKdQgMeta || {},
    alias: indexvlRQBVKdQgMeta?.alias || [],
    redirect: indexvlRQBVKdQgMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/orders/index.vue").then(m => m.default || m)
  },
  {
    name: settingsrGf2sDojyDMeta?.name ?? "service-settings",
    path: settingsrGf2sDojyDMeta?.path ?? "settings",
    children: [
  {
    name: languageew28PfUt3QMeta?.name ?? "service-settings-language",
    path: languageew28PfUt3QMeta?.path ?? "language",
    meta: languageew28PfUt3QMeta || {},
    alias: languageew28PfUt3QMeta?.alias || [],
    redirect: languageew28PfUt3QMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/settings/language.vue").then(m => m.default || m)
  },
  {
    name: _91id_93v3Ej1MywC2Meta?.name ?? "service-settings-loginProviders-id",
    path: _91id_93v3Ej1MywC2Meta?.path ?? "loginProviders/:id()",
    meta: _91id_93v3Ej1MywC2Meta || {},
    alias: _91id_93v3Ej1MywC2Meta?.alias || [],
    redirect: _91id_93v3Ej1MywC2Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKHnqSjHnHFMeta?.name ?? "service-settings-loginProviders",
    path: indexKHnqSjHnHFMeta?.path ?? "loginProviders",
    meta: indexKHnqSjHnHFMeta || {},
    alias: indexKHnqSjHnHFMeta?.alias || [],
    redirect: indexKHnqSjHnHFMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: rolesynKZP8aKfXMeta?.name ?? "service-settings-roles",
    path: rolesynKZP8aKfXMeta?.path ?? "roles",
    meta: rolesynKZP8aKfXMeta || {},
    alias: rolesynKZP8aKfXMeta?.alias || [],
    redirect: rolesynKZP8aKfXMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/settings/roles.vue").then(m => m.default || m)
  }
],
    meta: settingsrGf2sDojyDMeta || {},
    alias: settingsrGf2sDojyDMeta?.alias || [],
    redirect: settingsrGf2sDojyDMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/settings.vue").then(m => m.default || m)
  },
  {
    path: usersWSZrYreI4GMeta?.path ?? "users",
    children: [
  {
    name: _91id_93xmY2RRoPg6Meta?.name ?? "service-users-id",
    path: _91id_93xmY2RRoPg6Meta?.path ?? ":id()",
    children: [
  {
    name: userActivitiesLJwlhU58MdMeta?.name ?? "service-users-id-userActivities",
    path: userActivitiesLJwlhU58MdMeta?.path ?? "userActivities",
    meta: userActivitiesLJwlhU58MdMeta || {},
    alias: userActivitiesLJwlhU58MdMeta?.alias || [],
    redirect: userActivitiesLJwlhU58MdMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: userBaseDataF1uUUE8C60Meta?.name ?? "service-users-id-userBaseData",
    path: userBaseDataF1uUUE8C60Meta?.path ?? "userBaseData",
    meta: userBaseDataF1uUUE8C60Meta || {},
    alias: userBaseDataF1uUUE8C60Meta?.alias || [],
    redirect: userBaseDataF1uUUE8C60Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: userCommunicationsbqUAM2E0cSMeta?.name ?? "service-users-id-userCommunications",
    path: userCommunicationsbqUAM2E0cSMeta?.path ?? "userCommunications",
    meta: userCommunicationsbqUAM2E0cSMeta || {},
    alias: userCommunicationsbqUAM2E0cSMeta?.alias || [],
    redirect: userCommunicationsbqUAM2E0cSMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
],
    meta: _91id_93xmY2RRoPg6Meta || {},
    alias: _91id_93xmY2RRoPg6Meta?.alias || [],
    redirect: _91id_93xmY2RRoPg6Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/users/[id].vue").then(m => m.default || m)
  },
  {
    name: index5S6EfTLjAjMeta?.name ?? "service-users",
    path: index5S6EfTLjAjMeta?.path ?? "",
    meta: index5S6EfTLjAjMeta || {},
    alias: index5S6EfTLjAjMeta?.alias || [],
    redirect: index5S6EfTLjAjMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/users/index.vue").then(m => m.default || m)
  }
],
    name: usersWSZrYreI4GMeta?.name ?? undefined,
    meta: usersWSZrYreI4GMeta || {},
    alias: usersWSZrYreI4GMeta?.alias || [],
    redirect: usersWSZrYreI4GMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service/users.vue").then(m => m.default || m)
  }
],
    name: serviceklnPIKRbtpMeta?.name ?? undefined,
    meta: serviceklnPIKRbtpMeta || {},
    alias: serviceklnPIKRbtpMeta?.alias || [],
    redirect: serviceklnPIKRbtpMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241028122754/client/pages/service.vue").then(m => m.default || m)
  }
]